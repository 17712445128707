@import "_variables.scss";

.inputBar {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 10%;

  .vendorName {
    color: #fefefe;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .search {
    border-bottom: 2px solid #fefefe;
    width: 35%;
    min-width: 430px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;

    input[type="text"] {
      width: 100%;
      height: 40px;
      background: transparent;
      border: none;
      font-size: 24px;
      outline: none;
      text-align: center;
      color: #fefefe;
      font-family: "Montserrat";
    }
    .clearSearch {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      svg {
        height: 25px;
        width: 25px;
        stroke: $dark_text;
        transition: stroke 500ms ease-in-out;
        &:hover {
          stroke: $dark_text_highlight;
        }
      }
    }
  }
  .itemsArray {
    width: 500px;
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .itemsArrayList {
      list-style: none;
      height: 35px;
      width: 500px;

      a {
        background: #272b2bf8;
        padding: 4px 40px;
        margin: 1px;
        border: thin solid #272b2b;
        border-radius: 3px;
        cursor: pointer;
        color: #fefefe;
        text-transform: uppercase;
        width: 460px;
        display: flex;

        &:hover {
          background: #fefefe;
          color: #000;
        }
      }
    }
  }
}
.previewHeader {
  height: 320px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  .swatchView {
    width: auto;
  }
  .previewPlaceHolder {
    width: 332px;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 300px;
    max-width: 345px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 345px;
    background-color: #272c2c;
    border: thin solid #dddddd60;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fefefe;
  }
  .selectSwatchView {
    width: 400px;
    height: 300px;
    border-radius: 6px;
    background-color: #272c2c;
    border: thin solid #dddddd60;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fefefe;
  }
}
.inputSwatchContainer {
  min-height: 120px;
  min-width: 400px;
  width: 40vw;
  background-color: #272c2c;
  padding: 10px 20px 10px 30px;
  border-radius: 6px;
  margin: 5px 0px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  gap: 2px;

  .inputSwatchHeader {
    position: absolute;
    background-color: #fefefe50;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 20px;

    .inputSwatchHeaderTitle {
      transform: rotate(-90deg);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 10px;
    }
  }
  .dropZone {
    height: 100px;
    width: 120px;
    border: 2px red dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin-right: 2px;
    cursor: copy;
  }
  .dropZoneExpanded {
    height: 100px;
    width: 100%;
    border: 2px red dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin-right: 2px;
    cursor: copy;
  }
  .dropZoneUpload {
    height: 100px;
    width: 100px;
    border: 2px #fefefe solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fefefe;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 3px;
    transition: all 300ms ease-in-out;

    &:hover {
      border: 2px #272b2bf8 solid;
      color: #272b2bf8;
      background-color: #fefefe;
    }
  }
  .dropDeck {
    display: flex;
    gap: 2px;
  }

  .dragDropEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fefefe;
  }

  .dragDropItem {
    border-radius: 4px;
    border: thin solid black;
    height: 100px;
    min-width: 100px;
    overflow: hidden;
    position: relative;

    .deleteDragDropItem {
      background-color: black;
      position: absolute;
      width: 65px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(45deg);
      right: -25px;
      top: 0px;
      color: #fefefe;
      cursor: pointer;
      filter: drop-shadow(0px 0px 2px black);

      .deleteIcon {
        height: 15px;
        width: 20px;
        transform: rotate(45deg);
      }
    }

    .folderItemAdd {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: white;
      color: black;
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 12px;
      text-transform: uppercase;
      filter: drop-shadow(2px 4px 6px black);
      cursor: pointer;

      &:hover {
        background-color: #fefefe90;
      }
    }
  }
}

.actionBar {
  a {
    background-color: $dark_background;
    width: 460px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    text-decoration: none;
    color: $dark_text_highlight;
    text-transform: uppercase;
  }
  a:hover {
    color: $dark_background;
    background-color: $dark-text;
  }
}
.splitScreen {
  display: flex;
  width: 100%;

  .inputData {
    min-width: 45%;
  }
  .inputImage {
    min-width: 55%;
  }
}

// Input Data
.inputData {
  display: flex;
  justify-content: center;
  .inputDataPane {
    max-width: 600px;
    h2,
    h4 {
      padding: 0;
      margin: 0;
      color: #ddd;
    }
    form {
      display: flex;
      flex-wrap: wrap;
    }
    .infoHead {
      display: flex;

      div {
        width: 45%;
        padding: 5px;
      }
    }

    input[type="text"],
    input[type="number"],
    textarea {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      font-family: "Montserrat";
      font-size: 20px;
      color: #ddd;
      outline: none;
      resize: none;
    }

    select {
      width: 48%;
      font-size: 16px;
      text-transform: uppercase;
      font-family: "Montserrat";
      text-align: center;
      margin: 3px;
      height: 40px;
    }
    fieldset {
      border-radius: 5px;
      border: 4px solid #ddd;
      margin-bottom: 13px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px 20px;
      color: #ddd;
      position: relative;
      width: 100%;

      input[type="radio"],
      input[type="checkbox"] {
        margin: 5px 10px;
      }
      .priceInput {
        display: flex;
        align-items: baseline;

        h3 {
          font-size: 40px;
          margin: 0 3px;
        }
        input[type="number"] {
          font-size: 40px;
          width: 100px;
          text-align: center;
        }
      }
      .descriptionCounter {
        position: absolute;
        right: 30px;
        bottom: 10px;
      }
    }
    legend {
      color: #ddd;
      padding: 0 10px;
    }
    .searchTagsFieldset {
      flex-direction: column;
      .searchTagsBox {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .searchTags {
          border: thin solid #ddd;
          padding: 5px 10px;
        }
      }
      .searchTagsInputBox {
        display: flex;
        height: 30px;
        border: thin solid #ddd;
        width: 320px;
        justify-content: center;

        input[type="text"] {
          width: 220px;
          padding: 10px 20px;
        }
        button {
          width: 100px;
        }
      }
    }
  }
}
/// image pane

.imagePane {
  position: fixed;
  padding-left: 20px;
}
