.loginView {
  .loginViewContent {
    width: 400px;
    padding: 2em 1em 1em 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;
    border: thin solid black;
    border-radius: 3px;
    background-color: #ffffff50;

    h1 {
      font-size: 100px;
      color: #363b3b;
      transform: rotate(2deg);
      background: #00ffed;
      padding: 0 20px;
      border-radius: 2px 3px;
      filter: drop-shadow(2px 4px 6px #fefefe20);
    }
    h4 {
      color: #0a0f0f;
      margin-top: -40px;
      font-size: 15px;
      position: relative;
      top: 22px;
      text-align: left;
      width: 100%;
      font-family: "Montserrat-Bold";
    }
  }

  .loginForm {
    height: auto;
    background-color: #00000029;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 13px;
    padding: 20px;
    justify-content: space-between;
    width: 400px;
    transition: all ease-in-out 400ms;

    .loginView {
      padding: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }

    .loginInputGroup {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 2px;
    }
    .loginErrorMessage {
      font-size: 12px;
      text-align: right;
      color: pink;
    }

    button {
      height: 40px;
      font-size: 18px;
      font-family: "Montserrat-Bold";
      text-transform: uppercase;
      border-radius: 3px;
      border: none;
      margin-top: 30px;
      color: black;
      background-color: #fefefe;
    }
    button.button-disabled {
      color: rgb(102, 102, 102);
      background-color: #959595;
    }

    input[type="email"],
    input[type="password"] {
      height: 40px;
      font-size: 20px;
      text-align: center;
      font-family: "Montserrat";
      outline: none;
      background-color: transparent;
      color: #fefefe;
      border: none;
      border-bottom: dashed thin #fefefe50;
      padding: 25px 0;
      width: 100%;
    }
  }
}
@media only screen and (max-width: 765px) {
  .loginView {
    .loginViewContent {
      width: 350px;
      padding: 2em;
      display: flex;
      flex-direction: column;
      gap: 2em;
      align-items: center;
      border: thin solid black;
      border-radius: 3px;
      background-color: #ffffff50;

      h1 {
        font-size: 70px;
        color: #363b3b;
        transform: rotate(2deg);
        background: #00ffed;
        padding: 0 20px;
        border-radius: 2px 3px;
        filter: drop-shadow(2px 4px 6px #fefefe20);
      }
      h4 {
        color: #0a0f0f;
        margin-top: -40px;
        font-size: 15px;
        position: relative;
        top: 22px;
        text-align: left;
        width: 100%;
        font-family: "Montserrat-Bold";
      }
    }

    .loginForm {
      height: auto;
      background-color: #00000029;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      gap: 13px;
      padding: 20px;
      justify-content: space-between;
      width: 330px;
      transition: all ease-in-out 400ms;

      .loginView {
        padding: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
      }

      .loginInputGroup {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2px;
      }
      .loginErrorMessage {
        font-size: 12px;
        text-align: right;
        color: pink;
      }

      button {
        height: 40px;
        font-size: 18px;
        font-family: "Montserrat-Bold";
        text-transform: uppercase;
        border-radius: 3px;
        border: none;
        margin-top: 30px;
        color: black;
        background-color: #fefefe;
      }

      input[type="email"],
      input[type="password"] {
        height: 40px;
        font-size: 20px;
        text-align: center;
        font-family: "Montserrat";
        outline: none;
        background-color: transparent;
        color: #fefefe;
        border: none;
        border-bottom: dashed thin #fefefe50;
        padding: 25px 0;
        width: 100%;
      }
    }
  }
}
